<template>
  <div class="Afiliates">
    <b-image :src="computedImgSrc" />
    <div class="section search-section">
      <div class="columns is-multiline is-centered">
        <div class="column is-full">
          <p class="title is-2 has-text-centered has-text-zarzamora">
            {{ $t('giftcards.title') }}
          </p>
        </div>
        <div class="column is-three-fifths">
          <b-field>
            <b-input
              :placeholder="$t('giftcards.search-label')"
              size="is-medium"
              type="search"
              icon-right="magnify"
              v-model="query"
            >
            </b-input>
          </b-field>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="columns is-multiline">
        <div
          v-for="giftcard in computedGiftcards"
          :key="giftcard.name"
          class="column is-flex is-3 is-justify-content-center is-align-items-center"
        >
          <img :src="giftcard.src" :alt="giftcard.name" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Afiliates',
  data() {
    return {
      query: '',
      giftcards: [
        {
          name: 'Amazon',
          src: '/static/Giftcards/Amazon.svg',
        },
        {
          name: 'Cinepolis',
          src: '/static/Giftcards/Cinepolis.svg',
        },
        {
          name: 'Google Play',
          src: '/static/Giftcards/GooglePlay.svg',
        },
        {
          name: 'Nintendo',
          src: '/static/Giftcards/Nintendo.svg',
        },
        {
          name: 'PlayStation',
          src: '/static/Giftcards/PlayStation.svg',
        },
        {
          name: 'Spotify',
          src: '/static/Giftcards/Spotify.svg',
        },
        {
          name: 'Xbox',
          src: '/static/Giftcards/Xbox.svg',
        },
        {
          name: 'Netflix',
          src: '/static/Giftcards/Netflix.svg',
        },
        {
          name: 'Enviaflores',
          src: '/static/Giftcards/Enviaflores.svg',
        },
        {
          name: 'Starbucks',
          src: '/static/Giftcards/Starbucks.svg',
        },
      ],
    }
  },
  computed: {
    computedGiftcards() {
      return this.giftcards.filter(
        (giftcard) =>
          !giftcard.name
            .toLocaleLowerCase()
            .indexOf(this.query.toLocaleLowerCase().trim())
      )
    },
    computedImgSrc() {
      return this.$i18n.locale === 'es'
        ? '/static/update/Banner-Tarjetas-de-regalo.png'
        : '/static/update/Banner-Gift-Cards.jpg'
    },
  },
  methods: {},
}
</script>
<style scoped>
img {
  width: 200px;
  height: 200px;
  box-shadow: 0px 4px 8px #307fe240;
  border: 1px solid #307fe2;
  border-radius: 20px;
  padding: 12px;
}
</style>
<style>
.search-section {
  background: transparent linear-gradient(180deg, #c3d7ee 0%, #ddedff 100%) 0%
    0% no-repeat padding-box;
  border: 1px solid #a2b9d4;
  opacity: 1;
}
.search-section input {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #a2b9d4;
  border-radius: 13px;
  opacity: 1;
}
</style>
